@import "../../App.scss";

.project-container {
  min-height: 2000px;
  padding-top: 100px;
  padding-left: 80px;
  font-family: "Work Sans", sans-serif;
  font-weight: 200;
  background-color: white;
  z-index: 2;
}
