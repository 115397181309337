@import "../../App.scss";

.hero {
  margin-top: -5rem;
  padding-top: 5rem;
  height: 100vh;
  background: var(--clr-primary-10);
  position: relative;
}

.hero-center {
  height: 100%;
  display: grid;
  align-items: center;
}

.hero-img {
  display: none;
}

.hero .underline {
  margin-bottom: 1rem;
  margin-left: 0;
}
.hero-info {
  background: var(--clr-primary-10);
}

.hero-info h4 {
  color: var(--clr-grey-5);
}
.hero-icons {
  justify-items: flex-start;
}
.hero .btn {
  margin-top: 1.25rem;
  background: var(--clr-primary-5);
}

@media screen and (min-width: 992px) {
  .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 65%;
    right: 0;
    bottom: 0;
    background: var(--clr-white);
  }
  .hero-center {
    grid-template-columns: repeat(12, 1fr);
  }

  .hero h4 {
    font-size: 0.85rem;
  }
  .hero-info {
    grid-row: 1/1;
    grid-column: 1 / span 9;
  }
  .hero-img {
    display: block;
    grid-row: 1/1;
    grid-column: 6/-1;
  }
}
@media screen and (min-width: 1170px) {
  .hero h4 {
    font-size: 1rem;
  }

  .hero-info {
    grid-column: 1 / span 8;
  }

  .hero h4 {
    line-height: 1;
  }
}

.profile-pic {
  height: 50%;
  margin-left: 100px;
  z-index: 1;
  border-radius: 20px;
  box-shadow: var(--dark-shadow);
}
