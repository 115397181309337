.footer {
  background: #222;
  text-align: center;
  display: grid;
  place-items: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.footer p {
  margin-top: 0.25rem;
  color: var(--clr-white);
  font-weight: normal;
}
.footer p span {
  color: var(--clr-primary-5);
  margin-bottom: 0;
}
.footer-links {
  margin: 0 auto 1rem auto;
}
.footer .social-link {
  color: var(--clr-white);
}
.footer .social-link:hover {
  color: var(--clr-primary-5);
}
